/**
 * Direttiva che crea l'header visualizzato una volta effettuato il login in modalità utente. Esso conterrà il pulsante 
 * di logout, il titolo della pagina e il logout
 */
export interface IHeaderDirectiveScope extends ng.IScope {
	width: number;
	hideMenuButtons: boolean;
	openSidenavMenu: Function;
	searchContent: string;
	goToTrainingPlan: Function;
	goToHome: Function;
	goToNews: Function;
	goToNotifications: Function;
	goToHistory: Function;
	goToUserSettings: Function;
	goToUserMode: Function;
	goToLibrary: Function;
	goToWebinarList: Function;
	canAccessCourses: Function;
	isWebinarListVisible: Function;
	bgacademyApplicationData: any;
	startFreeSearch: Function;
	filters: any;
	globalApplicationData: any;
	cleanSearchedText: Function;
	canUserAccessCourses: Function;
	canSeeLibrary: boolean;
}
angular.module('app').directive("bgheader", ($window, $mdSidenav, $state, BgacademyApplicationData, GlobalApplicationData, $translate, $stateParams) => {
	return {
		link: link,
		restrict: 'AE',
		templateUrl: 'app/shared/header/header.html'
	};
	function link($scope: IHeaderDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Testo per la ricerca libera
		$scope.filters = {
			searchedContentText: $stateParams.searchedContent ? $stateParams.searchedContent : null
		};

		// Pulisce il filtro di ricerca testuale
		$scope.cleanSearchedText = () => {
			$scope.filters.searchedContentText = null;
		}

		// Collegamento all'oggetto principale
		$scope.bgacademyApplicationData = BgacademyApplicationData;

		$scope.globalApplicationData = GlobalApplicationData;

		$scope.canUserAccessCourses = () => {
			// Se l'utente non ha la mail nascondiamo alcuni menù perché probabilmente è un assistente commerciale o un neo-inserito
			return $scope.globalApplicationData.jwtPayload.user.email;
		}

		// Apertura Sidenav di sinistra
		$scope.openSidenavMenu = () => {
			$mdSidenav('sidenavMenuUser').toggle();
		}

		// Porta alla pagina di Home
		$scope.goToHome = () => {
			$state.go('app.bgacademyApp.home');
			$scope.cleanSearchedText();
		}

		// Porta alla pagina con i risultati della ricerca libera
		$scope.startFreeSearch = () => {
			if ($scope.filters.searchedContentText && $scope.filters.searchedContentText.length) {
				// Vado alla pagina di ricerca
				$state.go('app.bgacademyApp.search', { searchedContent: $scope.filters.searchedContentText });
			}
			return;
		}

		// Porta alla pagina del piano formativo
		$scope.goToTrainingPlan = () => {
			$state.go('app.bgacademyApp.trainingPlan');
			$scope.cleanSearchedText();
		}

		// Porta alla pagina delle news
		$scope.goToNews = () => {
			$state.go('app.bgacademyApp.news');
			$scope.cleanSearchedText();
		}

		// Porta alla pagina delle notifiche
		$scope.goToNotifications = () => {
			$state.go('app.bgacademyApp.notifications');
			$scope.cleanSearchedText();
		}

		// Porta alla pagina dei webinar
		$scope.goToWebinarList = () => {
			$state.go('app.bgacademyApp.webinarList');
			$scope.cleanSearchedText();
		}

		// Controlla che l'utente abbia accesso alla sezione dei corsi
		$scope.canAccessCourses = () => {
			return $scope.globalApplicationData.jwtPayload.auths.indexOf(BgacademyApplicationData.constants.BGACADEMY_USE_COURSE) !== -1;
		}

		// Controlla che l'utente abbia accesso alla sezione dei webinar
		$scope.isWebinarListVisible = () => {
			return $scope.globalApplicationData.jwtPayload.auths.indexOf(BgacademyApplicationData.constants.BGACADEMY_USE_WEBINAR) !== -1 || 
			$scope.globalApplicationData.jwtPayload.auths.indexOf(BgacademyApplicationData.constants.BGACADEMY_MANAGE_WEBINAR) !== -1 ||
			$scope.globalApplicationData.jwtPayload.auths.indexOf(BgacademyApplicationData.constants.BGACADEMY_WEBINAR_TEACHER) !== -1;
		}

		// Porta alla pagina dello storico
		$scope.goToHistory = () => {
			$state.go('app.bgacademyApp.history');
			$scope.cleanSearchedText();
		}

		// Porta alla pagina delle impostazioni utente
		$scope.goToUserSettings = () => {
			$state.go('app.bgacademyApp.userSettings');
			$scope.cleanSearchedText();
		}

		// Porta alla pagina delle impostazioni utente
		$scope.goToUserMode = () => {
			$scope.cleanSearchedText();

			if (GlobalApplicationData.jwtPayload && GlobalApplicationData.jwtPayload.auths && GlobalApplicationData.jwtPayload.auths.length && GlobalApplicationData.jwtPayload.auths.length > 0) {
				let isAdmin: boolean = false;
				let isSupervisor: boolean = false;
				let auths: Array<string> = GlobalApplicationData.jwtPayload.auths;
				angular.forEach(auths, function (auth, authIndex) {
					if (auth == BgacademyApplicationData.constants.BGACADEMY_MANAGE_ANNOUNCEMENT ||
						auth == BgacademyApplicationData.constants.BGACADEMY_MANAGE_COURSES ||
						auth == BgacademyApplicationData.constants.BGACADEMY_MANAGE_NOTE ||
						auth == BgacademyApplicationData.constants.BGACADEMY_MANAGE_QUESTION ||
						auth == BgacademyApplicationData.constants.BGACADEMY_MANAGE_SUCCESS_STORY ||
						auth == BgacademyApplicationData.constants.BGACADEMY_MANAGE_TEMPLATE) {
						isAdmin = true;
					}

					// Verifico se è supervisore
					if (auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_COURSES ||
						auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_TRAININGS ||
						auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_USERS ||
						auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_GROUPS ||
						auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_ANNOUNCEMENTS ||
						auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_DASHBOARD ||
						auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_TEMPLATES ||
						auth == BgacademyApplicationData.constants.BGACADEMY_SUPERVISION_ITEMS) {
						isSupervisor = true;
					}

					if (auth == BgacademyApplicationData.constants.BGACADEMY_SEE_LIBRARY) {
						$scope.canSeeLibrary = true;
					}
				});

				if (isAdmin || isSupervisor) {
					$state.go('app.bgacademyApp.userMode');
				} else {
					$state.go('app.bgacademyApp.home');
				}
			} else {
				$state.go('app.bgacademyApp.home');
			}
		}

		// Porta alla pagina di Library
		$scope.goToLibrary = () => {
			$state.go('app.bgacademyApp.library');
			$scope.cleanSearchedText();
		}

		// Traduzione per ricerca contenuto
		$scope.searchContent = $translate.instant("header.FREE_SEARCH_PLACEHOLDER");

		// Da 790px in giù, le voci del menu si nascondono lasciando spazio solo all'Hamburge menu
		angular.element($window).bind('resize', () => {
			$scope.width = $window.innerWidth;

			if ($scope.width < 790) {
				$scope.hideMenuButtons = true;
			} else {
				$scope.hideMenuButtons = false;
			}
			$scope.$digest();
		})

		// Stessa cosa al caricamento della pagina
		angular.element(document).ready(() => {
			$scope.width = $window.innerWidth;

			if ($scope.width < 790) {
				$scope.hideMenuButtons = true;
			} else {
				$scope.hideMenuButtons = false;
			}
			$scope.$digest();
		})
	}
});